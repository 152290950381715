import { defineStore } from "pinia";
import { client, urls, RBACclient, setBrandId } from "@/apiClient";
// import { urls as iamUrls } from "../modules/UserManagement/urls";
import { useMerchantsStore } from "@/stores/merchants";

export const useBrandsStore = defineStore("brands", {
  state: () => ({
    brands: [],
    selectedBrandUri:null,
    brandsLoading: true,
  }),
  getters: {
    selectedBrandId() {
      return this.selectedBrand?.id;
    },
    selectedBrand(store) {
      return store.brands.find((brand) => brand.uri === store.selectedBrandUri);
    },
  },
  actions: {
    async fetchBrands() {
      // const { data } = await RBACclient.get(iamUrls.getProfileBrands);
      // this.brands = data.list;
      const { data } = await client.getLatest(urls.brands);
      this.brands = data.brands;
      if(this.selectedBrandUri) {
        const brand = this.brands.find((brand) => brand.uri === this.selectedBrandUri);
        if (brand) {
          setBrandId(brand.id);
          const merchantStore = useMerchantsStore();
          merchantStore.fetchMerchants(brand.id);
        }
      }
      this.brandsLoading = false;

      if (this.brands.length === 1) {
        this.setBrandUri(this.brands[0].uri);
      }
    },
    setBrandUri(uri) {
      const brand = this.brands.find((brand) => brand.uri === uri);
      if (brand) {
        setBrandId(brand.id);
        const merchantStore = useMerchantsStore();
        merchantStore.fetchMerchants(brand.id);
      }
      this.selectedBrandUri = uri;
    },
    async deassignManager(brandId) {
      try {
        // await RBACclient.delete(iamUrls.deleteProfileBrands(brandId));
        // this.brands = this.brands.filter(b => b.id !== brandId);
      } catch (error) {
        console.error(error);
      }
    },
  },
});
