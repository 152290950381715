import { defineStore } from "pinia";
import { client } from "@/apiClient";
import { urls } from "../modules/Gifting/urls";

export const useGiftingStore = defineStore("gifting", {
  state: () => ({
    giftingList: [],
    selectedGifting: {},
    selectedGiftingId: '',
    giftingLoader: true,
  }),

  actions: {
    async fetchGiftingList(brandId) {
      this.giftingLoader = true;
      const { data } = await client.getLatest(urls.getGiftingListUrl, {
        params:{
          brandId: brandId,
        }
      })
      this.giftingLoader =false;
      const giftingList  = data.brands ?? [];
      const selectedGifting = giftingList ? giftingList[0] : '';
      const selectedGiftingId = selectedGifting ? selectedGifting.id : '';
      this.$patch({
        giftingList,
        selectedGifting,
        selectedGiftingId
      });
    },
    selectGifting(gifting) {
      this.selectedGifting = gifting;
      this.selectedGiftingId = gifting.id;
    },
  },
});
