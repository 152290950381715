import MerchantPicker from './components/MerchantPicker.vue'

export const moduleSlug = 'cashier'
export const moduleAuth = 'Cashier';

export const children = [
  // {
  //   path: "",
  //   component: () => import("./pages/Orders.vue"),
  //   props: { selectedModule: moduleSlug},
  //   meta: {
  //     title: "Cashier",
  //     linkTitle: "Cashier",
  //     linkIcon: "cash",
  //     showInNav: true,
  //     permission: "Cashier",
  //   },
  // },
  {
    path: "",
    component: () => import("./pages/Orders.vue"),
    props: { selectedModule: moduleSlug},
    meta: {
      title: "Cashier",
      linkTitle: "Cashier",
      linkIcon: "cash",
      showInNav: true,
      permission: "Cashier",
    },
  }
]

export const picker = MerchantPicker;