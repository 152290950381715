export const moduleSlug = "integrations";
export const moduleAuth = "Wallet";

export const children = [
  {
    path: '',
    component: () => import('./pages/IntegrationList.vue'),
    props: { selectedModule: moduleSlug },
    meta: {
      title: 'Integrations',
      linkIcon: 'code-fork',
      showInNav: true,
      permission: "Integrations.Read",
    },
  },
  {
    path: "create",
    component: () => import("./pages/IntegrationCrud.vue"),
    props: { selectedModule: moduleSlug },
    meta: {
      title: "Integrations",
      showInNav: false,
      permission: "Integrations.Write",
    },
  },
  {
    path: 'update',
    component: () => import('./pages/IntegrationCrud.vue'),
    props: { selectedModule: moduleSlug },
    meta: {
      title: 'Edit Integration',
      showInNav: false,
      permission: "Integrations.Write",
    },
  },
];
