export const moduleSlug = 'engagement'
export const moduleAuth = 'Engagement';

export const children = [
  {
    path: "",
    component: () => import("./pages/CampaignLanding.vue"),
    meta: {
      title: "Create",
      linkTitle: "Create",
      linkIcon: "file-o",
      showInNav: true,
      permission: "Engagement.Create",
    },
  },
  // {
  //   path: "",
  //   redirect: "/engagement/create",
  //   meta: {
  //     showInNav: false,
  //   },
  // },
  // {
  //   path: "create",
  //   component: () => import("./pages/CampaignLanding.vue"),
  //   meta: {
  //     title: "Create",
  //     linkTitle: "Create",
  //     linkIcon: "file-o",
  //     showInNav: true,
  //   },
  // },
  {
    path: "create/announcement",
    component: () => import("./components/CreateAnnonce.vue"),
    meta: {
      title: "Create Annoucement Campaign",
      showInNav: false,
      permission: "Engagement.Create.Announcement",
    },
  },
  {
    path: "create/winback",
    component: () => import("./components/CreateAnnonce.vue"),
    meta: {
      title: "Create Winback Campaign",
      showInNav: false,
      permission: "Engagement.Create.Winback",
    },
  },
  {
    path: "create/scheduled",
    component: () => import("./components/CreateAnnonce.vue"),
    meta: {
      title: "Create Scheduled Campaign",
      showInNav: false,
      permission: "Engagement.Create.Scheduled",
    },
  },
  {
    path: "create/automated",
    component: () => import("./components/CreateAnnonce.vue"),
    meta: {
      title: "Create Automated Campaign",
      showInNav: false,
      permission: "Engagement.Create.Automated",
    },
  },
  {
    path: "create/profile-builder",
    component: () => import("./components/CreateAnnonce.vue"),
    meta: {
      title: "Create Profile Builder Campaign",
      showInNav: false,
      permission: "", // TODO
    },
  },
  {
    path: "create/birthday",
    component: () => import("./components/CreateAnnonce.vue"),
    meta: {
      title: "Create Birthday Campaign",
      showInNav: false,
      permission: "Engagement.Create.Birthday",
    },
  },
  {
    path: "create/anniversary",
    component: () => import("./components/CreateAnnonce.vue"),
    meta: {
      title: "Create Anniversary Campaign",
      showInNav: false,
      permission: "Engagement.Create.Anniversary",
    },
  },
  {
    path: "create/quizzes",
    component: () => import("./components/CreateAnnonce.vue"),
    meta: {
      title: "Create Quiz",
      showInNav: false,
      permission: "", // TODO
    },
  },
  {
    path: "create/marketing-automation",
    component: () => import("./components/CreateAnnonce.vue"),
    meta: {
      title: "Create Marketing Automation",
      showInNav: false,
      permission: "Engagement.Create.MarketingAutomation",
    },
  },
  {
    path: "create/A-b/testing",
    component: () => import("./components/CreateAnnonce.vue"),
    meta: {
      title: "Create A-B testing",
      showInNav: false,
      permission: "", // TODO
    },
  },
  
  {
    path: "drafts",
    component: () => import("./pages/CampaignDrafts.vue"),
    meta: {
      title: "Drafts",
      linkTitle: "Drafts",
      linkIcon: "files-o",
      showInNav: true,
      permission: "Engagement.Drafts",
    },
  },
  {
    path: "scheduled",
    component: () => import("./pages/CampaignScheduled.vue"),
    meta: {
      title: "Campaign Scheduled",
      linkTitle: "Scheduled",
      linkIcon: "clock-o",
      showInNav: true,
      permission: "Engagement.Scheduled",
    },
  },
  {
    path: "automations",
    component: () => import("./pages/CampaignAutomated.vue"),
    meta: {
      title: "Campaign Automations",
      linkTitle: "Automations",
      linkIcon: "plus",
      showInNav: true,
      permission: "Engagement.Automations",
    },
  },
  {
    path: "history",
    component: () => import("./pages/CampaignList.vue"),
    meta: {
      title: "Campaign History",
      linkTitle: "History",
      linkIcon: "calendar-o",
      showInNav: true,
      permission: "Engagement.History",
    },
  },
  {
    path: "automations/history/:campaignId",
    component: () => import("./pages/CampaignList.vue"),
    meta: {
      title: "Automation Campaign History",
      showInNav: false,
      permission: "Engagement.Automations.Details.History",
    },
  },
  {
    path: "message-details/:campaignId",
    component: () => import("./pages/CampaignLogs.vue"),
    meta: {
      title: "Campaign Message Details",
      showInNav: false,
      permission: "Engagement.History.Details.Logs",
    },
  },

  {
    path: "revenue-impression/:campaignId",
    component: () => import("./pages/RevenueImpression.vue"),
    meta: {
      title: "Campaign Revenue Impression",
      showInNav: false,
      permission: "Engagement.Automations.Details.History",
    },
  },
  {
    path: "automations/message-details/:campaignId",
    component: () => import("./pages/AutomationCampaignLogs.vue"),
    meta: {
      title: "Automation Campaign Message Details",
      showInNav: false,
      permission: "Engagement.Automations.Details.Logs",
    },
  },
  {
    path: "revenue-generated/:campaignId",
    component: () => import("./pages/RevenueGenerated.vue"),
    meta: {
      title: "Campaign Revenue Generated",
      showInNav: false,
      permission: "Engagement.History.Details",
      permission: "Engagement.Automations.Details.History",
    },
  },
  {
    path: "revenue-impression/:campaignId",
    component: () => import("./pages/RevenueImpression.vue"),
    meta: {
      title: "Campaign Revenue Impression",
      showInNav: false,
      permission: "Engagement.History.Details",
    },
  },
  {
    path: "exclude-customers/:campaignId",
    component: () => import("./pages/ExcludeCustomersReport.vue"),
    meta: {
      title: "Campaign exclude customers",
      showInNav: false,
      permission: "Engagement.History.Details",
    },
  },
  {
    path: "history/details/:campaignId",
    component: () => import("./pages/CampaignDetails.vue"),
    meta: {
      title: "Campaign Details",
      modulePage: "history",
      showInNav: false,
      permission: "Engagement.History.Details",
    },
  },
  {
    path: "automation/details/:campaignId",
    component: () => import("./pages/CampaignDetails.vue"),
    meta: {
      title: "Campaign Automation Details",
      showInNav: false,
      modulePage: "automation",
      parent: "automations",
      permission: "Engagement.Automations.Details",
    },
  },
];
