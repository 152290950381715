import { defineStore } from "pinia";
import { client } from "@/apiClient";
import { urls } from "../modules/Loyalty/urls";

export const useLoyaltyStore = defineStore("loyalty", {
  state: () => ({
    loyalties: [],
    selectedLoyalty: {},
    selectedLoyaltyId: '',
  }),
  actions: {
    async fetchLoyalties(brandId) {
      const { data } = await client.getLatest(urls.getLoyaltyListDataUrl, {
        params:{
          brandId: brandId,
        }
      })
      const loyalties  = data.loyalties;
      const selectedLoyalty = loyalties[0];
      const selectedLoyaltyId = selectedLoyalty.id;
      this.$patch({
        loyalties,
        selectedLoyalty,
        selectedLoyaltyId,
      });
    },
    selectLoyalty(loyalty) {
      this.selectedLoyalty = loyalty;
      this.selectedLoyaltyId = loyalty.id;
    },
  },
});
