<template>
    <button class="btn btn-secondary btn-sm" @click="openModal()">
        Get Shareable Link
        <i class="fa4 fa-link"></i>
    </button>
    <div class="merchant-picker">
        <fr-merchant-picker
            style="width: auto; border: 0 !important; color: #fff;"
            singleSelect
            :merchants="selectedBrandMerchants"
            @update:selectedMerchantIds="selectedMerchantIds"
            v-model="selectedMerchants"
        />
    </div>
    <fr-modal :value="modalOpen" @cancel="modalOpen = false" @hidden="modalOpen = false" @ok="generateLink" :showFooter="linkGenerationStatus !== 'generated'">
        <div v-if="linkGenerationStatus === 'notGenerated'">
            This will generate a sharable link which will allow access to this dashboard <strong>without logging in.</strong>
            <br>
            Are you sure you want to continue?
        </div>
        <div v-else-if="linkGenerationStatus === 'generating'">
            Generating link...
        </div>
        <div v-else-if="linkGenerationStatus === 'generated'">
            Link generated! Copy the link below:
            <br><br>
            <div class="input-group mb-3">
                <input type="text" class="form-control" :value="generatedLink" readonly>
                <button class="input-group-text" data-tooltip="Click to copy!" @click="copyLink()">
                    {{  copyStatus === 'notCopied' ? 'Copy' : 'Copied!' }}
                </button>
            </div>
        </div>
    </fr-modal>
</template>

<script>
import { FrMerchantPicker, FrModal } from "@froogal.ai/ui";
import { storeToRefs } from "pinia";
import { useMerchantsStore } from "@/stores/merchants";
import { urls, client } from "@/apiClient";

export default {
    name: 'MerchantPicker',
    components: {
        FrMerchantPicker,
    },
    setup() {
        const merchantList = useMerchantsStore();
        const { selectedBrandMerchants, selectedMerchants } = storeToRefs(merchantList);
        const { selectedMerchantIds } = merchantList;
        return {
            selectedBrandMerchants,
            selectedMerchants,
            selectedMerchantIds,
        };
    },
    data() {
        return {
            modalOpen: false,
            linkGenerationStatus: 'notGenerated',
            generatedLink: '',
            copyStatus: 'notCopied',
        };
    },
    mounted() {
        const token = new URLSearchParams(window.location.search).get('token');
        if (token) {
            this.generatedLink = window.location.href + '?token=' + token;
            this.linkGenerationStatus = 'generated';
        }
    },
    methods: {
        openModal() {
            this.modalOpen = true;
        },
        async generateLink() {
            if (this.linkGenerationStatus !== 'notGenerated') {
                return;
            }
            this.linkGenerationStatus = 'generating';
            try {
                const name = (new Date()).toString().split('(')[0].trim();
                const {data} = await client.post(urls.generatePersonalAccessToken, {
                    name: 'Cashier Token ' + name,
                });
                this.generatedLink = window.location.href + '?token=' + data.accessToken;
                this.linkGenerationStatus = 'generated';
            } catch (error) {
                const message = error.response?.data?.message || error.message;
                this.$toast(message + '. Link Generation Failed!', 'error');
                this.linkGenerationStatus = 'notGenerated';
            }
        },
        copyLink() {
            navigator.clipboard.writeText(this.generatedLink).then(() => {
                this.copyStatus = 'copied';
                this.$toast('Link copied to clipboard!', 'success');
            }).catch(error => {
                this.$toast('Failed to copy link!', 'error');
            });
        },
    }
};
</script>

<style>
.merchant-picker button span {
    display: none;
}
</style>