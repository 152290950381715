import { createRouter, createWebHistory } from "vue-router";

import Error404Page from "../views/Error404Page.vue";
import BrandNotFound from "../views/BrandNotFound.vue";
import * as DashboardRoutes from "../modules/Dashboard/routes";
import * as LoyaltyRoutes from "../modules/Loyalty/routes";
import * as CustomersRoutes from "../modules/Customers/routes";
import * as FeedbackRoutes from "../modules/Feedback/routes";
import * as CampaignsRoutes from "../modules/Campaigns/routes";
import * as GiftingRoutes from "../modules/Gifting/routes";
import * as SeamlessRoutes from "../modules/Seamless/routes";
import * as ReferralRoutes from "../modules/Referral/routes";
import * as UrlShortenerRoutes from "../modules/UrlShortner/routes";
import * as OfferBoxRoutes from "../modules/Offers/routes";
import * as AlertManager from "../modules/AlertManager/routes";
import * as ImportsRoutes from "../modules/Imports/routes";
import * as UserManagementRoutes from "../modules/UserManagement/routes";
import * as WalletRoutes from "../modules/Wallet/routes";
import * as PhygitalRoutes from "../modules/Phygital/routes";
import * as CashierRoutes from "../modules/Cashier/routes"
import * as IntegrationRoutes from "../modules/Integrations/routes";

import BrandLayout from "../layout/BrandLayout.vue";
import BrandName from "../layout/BrandName.vue";
import ModuleLayout from "../layout/ModuleLayout.vue";
import ModuleSelector from "../layout/ModuleSelector.vue";
import ProfileSettings from "../components/ProfileSettings.vue";
import HtmlEditor from "@/components/HtmlEditor.vue";

export const modules = [
  DashboardRoutes,
  CustomersRoutes,
  LoyaltyRoutes,
  FeedbackRoutes,
  CampaignsRoutes,
  GiftingRoutes,
  SeamlessRoutes,
  ReferralRoutes,
  UrlShortenerRoutes,
  OfferBoxRoutes,
  AlertManager,
  ImportsRoutes,
  UserManagementRoutes,
  WalletRoutes,
  PhygitalRoutes,
  CashierRoutes,
  IntegrationRoutes
];

const routes = [
  {
    path: '/profile-settings',
    component: BrandLayout,
    children: [
      {
        path: '',
        components:{
          default: ProfileSettings,
          brand: BrandName
        },
        props: {
          brand: true
        }
      }
    ]
  },
  {
    path: "/:brand",
    component: BrandLayout,
    children:
      modules
        .map((module) => ({
          path: module.moduleSlug,
          components: {
            default: ModuleLayout,
            module: ModuleSelector,
            brand: BrandName
          },
          props: {
            default: {
              permission: module.moduleAuth,
              selectedModule: module.moduleSlug,
              subNavigation: module.children,
              quickLinks: module.quickLinks,
              picker: module.picker
            },
            module: {
              permission: module.moduleAuth,
              selectedModule: module.moduleSlug,
            },
            brand: true
          },
          children: module.children.filter(route => route.meta.excludeInRoute !== true).concat([
            {
              path: ":catchAll(.*)",
              component: Error404Page,
            }
          ]),
        }))
        
        .concat(
          [
            {
              path: '/',
              components: {
                default: BrandLayout,
                brand: BrandName
              },
              props: {
                brand: true
              }
            },
            {
              path: "/:brand",
              components: {
                module: ModuleSelector,
                brand: BrandName
              },
              props: {
                module: {
                  permission: null,
                  selectedModule: null,
                  persistent: true,
                  moduleDialog: true
                },
                brand: true
              },
            },
            {
              path: ":catchAll(.*)",
              components: {
                default: Error404Page,
                module: ModuleSelector,
                brand: BrandName
              },
              props: {
                module: {
                  permission: null,
                  selectedModule: null,
                },
                brand: true
              },
            },
          ]
        )
  }
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  mode: "history",
  base: import.meta.env.BASE_URL,
  routes,
});

const DEFAULT_TITLE = "Froogal";
router.beforeEach((to) => {
  if (to.path.length > 1) {
    if (to.path.endsWith('/')) {
      return {
        path: to.path.substring(0, to.path.length - 1),
      }
    }
  }
})


router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  document.title = to.meta.title || DEFAULT_TITLE;
});

export default router;
