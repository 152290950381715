<template>
  <router-view v-if="session.token" />
</template>

<script setup>
import 'bootstrap';
import { useSessionStore } from './stores/session';
const session = useSessionStore();
session.fetchToken();
</script>

<style>
@import "bootstrap";
@import "@froogal.ai/ui/dist/style.css";
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');
/* @import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css'); */
@import url('./assets/font-awesome/css/font-awesome.css');

:root {
  --bs-body-font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  --lightest-gray: #F2F4F5;
  --light-gray: #788494;
  --dark-gray: #3B444D;
  --brand-yellow: #FAB511;
  --brand-blue: #2869B4;
  --brand-green: #00BA7D;
  --brand-purple: #59348B;
  --brand-red: #CB533D;
  --bs-primary: var(--brand-blue);
  --bs-success: var(--brand-green);
  --bs-warning: var(--brand-yellow);
  --bs-error: var(--brand-red);
  --bs-secondary: var(--dark-gray);
  --bs-purple: var(--brand-purple);
}

body {
  color: var(--dark-gray);
  background-color: #F2F4F6;
}

.reportrange-text {
  padding: 0.375rem 0.75rem !important;
}

.form-control:focus {
  border-color: var(--brand-green);
  box-shadow: 0 0 0 0.05rem var(--brand-green);
}

.btn-success,
.btn-success:visited {
    background-color: #28B468 !important;
}

.btn-success:hover,
.btn-success:active,
.btn-success:focus {
    background-color: var(--brand-green) !important;
    border-color: #28B468 !important;
}

</style>
