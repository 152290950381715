<template>
    <fr-nav-side-view :side-details="sideDetails">
        <div class="brandDropBoxClass">
            <brand-selector v-if="brands.length !== 1" :selected-brand="selectedBrand"></brand-selector>
            <package-list :selected-brand="selectedBrand" v-if="selectedBrand?.tags?.canShowCreaditsInfo"></package-list>
            <user-details :profile="user"></user-details>
            <button  @click="clearStorage()" class="btn w-100 fw-bolder" style="font-size: 16px">Logout</button>
            <div class="d-flex justify-content-center version-class">v {{ applicationVersion }}</div>
        </div>
    </fr-nav-side-view>
</template>
<script>
import { FrNavSideView } from "@froogal.ai/ui"
import { useBrandsStore } from "@/stores/brands";
import { useUserProfileStore } from "@/stores/user-profile";
import { storeToRefs } from "pinia";
import BrandSelector from "../components/BrandSelector.vue";
import PackageList from "../components/PackageList.vue";
import UserDetails from "../components/UserDetails.vue";
import { urls,client } from "@/apiClient";
import { version } from "../../package.json"

export default {
    setup() {
        const brandStore = useBrandsStore();
        const profileStore = useUserProfileStore();
        const { selectedBrand, brands } = storeToRefs(brandStore);
        const { user } = storeToRefs(profileStore);
        return {
            selectedBrand,
            brands,
            user,
        };
    },
    components: {
        BrandSelector,
        PackageList,
        UserDetails,
        FrNavSideView
    },
    data() {
        return {
            applicationVersion: version
        }
    },
    computed: {
        sideDetails: function () {
            return {
                image: this.selectedBrand?.logo,
                heading: this.selectedBrand?.name,
                text: this.user?.name,
            };
        },
    },
    methods: {
        async clearStorage() {
            await client.postLatest(urls.logout)
            localStorage.clear();
            window.location.href = '/'
        }
    }
};
</script>
<style>
.brandDropBoxClass {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}
.brandDropBoxClass::-webkit-scrollbar {
 
  width: 3px;
  border-radius: 10%;
}

.brandDropBoxClass::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.brandDropBoxClass::-webkit-scrollbar-thumb {
  background: #888;
}

.brandDropBoxClass::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 10px;
}

.version-class {
    font-size: 12px;
    font-weight: 500;
    color: grey;
    margin-bottom: -20px;
}
</style>