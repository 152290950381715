import { defineStore } from "pinia";
import { client, urls } from "@/apiClient";
import { useBrandsStore } from "./brands";

export const useMerchantsStore = defineStore("merchants", {
  state: () => ({
    brandWiseMerchants: {},
    selectedMerchants: [],
    currency: {}
  }),
  getters: {
    selectedBrandMerchants(store) {
      const brands = useBrandsStore();
      return store.brandWiseMerchants[brands.selectedBrandId] || [];
    },
  },
  actions: {
    async fetchMerchants(brandId) {
      if (!brandId || this.brandWiseMerchants[brandId]) {
        return;
      }
      const { data } = await client.getLatest(urls.merchants, {
        params: {
          brandId: brandId,
        },
      });
      this.brandWiseMerchants[brandId] = data.merchants;
      this.currency = data.defaultCurrency;
    },
    selectedMerchantIds(store){
      this.selectedMerchants = store;
    }
  },
});
