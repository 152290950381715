import axios from "axios";
import axiosInterceptor from "./axios.interceptor";

import qs from "qs";

const setBaseUrl = () => {
  const host = window.location.hostname;
  let hostMapping;
  try {
    hostMapping = JSON.parse(import.meta.env.VUE_APP_HOST_MAPPING || "{}");
  } catch (e) {
    hostMapping = {};
  }
  const froogalCoreUrl =
    hostMapping[host] || import.meta.env.VUE_APP_FROOGAL_CORE_URL || "";
  return froogalCoreUrl.replace(/\/$/g, "") + "/";
};
export const baseUrl = setBaseUrl();
export const actionUrl = (import.meta.env.VUE_APP_FROOGAL_ACTION_URL || "").replace(/\/$/g, "") + "/";
export const RBACUrl = (import.meta.env.VUE_APP_RBAC_URL || import.meta.env.VUE_APP_FROOGAL_CORE_URL).replace(/\/$/g, "") + "/";

const oauthServer = import.meta.env.VUE_APP_OAUTH_SERVER_URL;

export const client = axios.create({
  baseURL: baseUrl,
  paramsSerializer: function (params) {
    const a = qs.stringify(params, { arrayFormat: "brackets" });
    return a;
  },
});

const latestTokens = {};
const latestTokensMap = new Map();

const latestFactory = function (method) {
  return function (url, config, symbol = null) {
    const key = Math.round(Math.random() * 1000000);
    if (symbol) {
      latestTokensMap.set(symbol, key);
    } else {
      latestTokens[url] = key;
    }
    const getTokenKey = () => symbol ? latestTokensMap.get(symbol) : latestTokens[url];
    // TODO: cancel request
    return new Promise(function (resolve, reject) {
      client[method](url, config)
        .then((result) => {
          if (key === getTokenKey()) {
            resolve(result);
          }
        })
        .catch((e) => {
          if (key === getTokenKey()) {
            reject(e);
          }
        });
    });
  };
}

client.getLatest = latestFactory('get');

client.postLatest = latestFactory('post');

export const RBACclient = axios.create({
  baseURL: RBACUrl,
  paramsSerializer: function (params) {
    const a = qs.stringify(params, { arrayFormat: 'brackets' });
    return a;
  },
});

axiosInterceptor.setupInterceptors(client);
axiosInterceptor.setupInterceptors(RBACclient);

export const urls = {
  logout: `${oauthServer}/api/logout`,
  loginAs: `${oauthServer}/login-as`,
  generatePersonalAccessToken: `${oauthServer}/oauth/personal-access-tokens`,
  brands: "business/api/brands",
  merchants: "business/api/merchants",
  profile: "business/api/profile",
  permissions: "business/api/permissions",
  creditsData: "business/dashboard-data/get-credits",
  getMerchantUsageCreditsData: "business/dashboard-data/get-merchant-usage-credits-data",
};

export function setToken(token) {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}
export function setBrandId(brandId) {
  client.defaults.headers.common["X-Brand-ID"] = brandId;
}
client.defaults.headers.common["X-Dashboard-Version"] = "2";
